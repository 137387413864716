
import React, { useState, useEffect } from 'react';
import './Modals.css'

function StepModal({ step, onClose, onSave, onDelete }) {
  const [text, setText] = useState('');

  useEffect(() => {
    if (step) {
      setText(step.text);
    }
  }, [step]);

  return (
    <div className="modal">
      <div className="modal-content">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Введите описание шага..."
        />
        <div className="modal-buttons">
          <button onClick={() => onSave(text)}>Сохранить</button>
          {step && <button onClick={onDelete}>Удалить</button>}
          <button onClick={onClose}>Закрыть</button>
        </div>
      </div>
    </div>
  );
}

export default StepModal;