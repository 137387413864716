
import React, { useState } from 'react';
import IngredientModal from '../Modals/IngredientModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './IngredientsList.css';


function IngredientsList({ ingredients, setIngredients }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const getWarehouseData = () => {
    const warehouseData = JSON.parse(localStorage.getItem('warehouseCategories')) || [];
    return warehouseData.reduce((acc, category) => {
      return [...acc, ...category.ingredients];
    }, []);
  };

  const calculateBrutto = (netto, wastePercent) => {
    if (!netto || !wastePercent) return '';
    return Math.round(netto / (1 - wastePercent / 100));
  };

  const calculateCost = (brutto, pricePerKg) => {
    if (!brutto || !pricePerKg) return '';
    return Math.round((brutto / 1000) * pricePerKg);
  };

  const getWarehouseIngredient = (name) => {
    const warehouseIngredients = getWarehouseData();
    return warehouseIngredients.find(ing => ing.name === name);
  };

  const handleAdd = () => {
    setSelectedIngredient(null);
    setShowModal(true);
  };

  const handleRowClick = (ingredient) => {
    setSelectedIngredient(ingredient);
    setShowModal(true);
  };

  const handleSave = (newIngredient) => {
    const warehouseIngredient = getWarehouseIngredient(newIngredient.name);
    let updatedIngredient;
    if (warehouseIngredient) {
        const readyWeight = Math.round(newIngredient.netto * (1 - newIngredient.waste / 100));
        updatedIngredient = {
            ...newIngredient,
            coldWaste: warehouseIngredient.coldWaste,
            price: warehouseIngredient.price,
            brutto: calculateBrutto(newIngredient.netto, warehouseIngredient.coldWaste),
            cost: calculateCost(newIngredient.brutto, warehouseIngredient.price),
            readyWeight: readyWeight
        };
    } else {
        const readyWeight = Math.round(newIngredient.netto * (1 - newIngredient.waste / 100));
        updatedIngredient = {
            ...newIngredient,
            brutto: calculateBrutto(newIngredient.netto, newIngredient.coldWaste || 0),
            readyWeight: readyWeight
        };
    }
    if (selectedIngredient) {
        setIngredients(ingredients.map(ing => 
            ing.id === selectedIngredient.id ? updatedIngredient : ing
        ));
    } else {
        setIngredients([...ingredients, updatedIngredient]);
    }
    setShowModal(false);
  };

  const handleDelete = (ingredientId) => {
    setIngredients(ingredients.filter(ing => ing.id !== ingredientId));
    setShowModal(false);
  };

  const calculateTotalWeight = () => {
    return ingredients.reduce((sum, ing) => sum + (parseInt(ing.readyWeight) || 0), 0);
  };

  const calculateTotalCost = () => {
    return ingredients.reduce((sum, ing) => sum + (parseInt(ing.cost) || 0), 0);
  };

  const warehouseIngredients = getWarehouseData();

  return (
    <div className="ingredients-container">
      <div className="ingredients-header">
        <div className="ingredient-name">Ингредиент</div>
        <div className="ingredient-brutto">Брутто</div>
        <div className="ingredient-netto">Нетто</div>
        <div className="ingredient-waste">%</div>
        <div className="ingredient-ready">Выход</div>
        <div className="ingredient-cost">Цена</div>
      </div>
      <div className="ingredients-list">
        {ingredients.map((ingredient, index) => (
          <div 
            key={index} 
            className="ingredient-row"
            onClick={() => handleRowClick(ingredient)}
            style={{ cursor: 'pointer' }}
          >
            <div className="ingredient-name">{ingredient.name}</div>
            <div className="ingredient-brutto">{ingredient.brutto || '-'}</div>
            <div className="ingredient-netto">{ingredient.netto || '-'}</div>
            <div className="ingredient-waste">{ingredient.waste || '-'}</div>
            <div className="ingredient-ready">{ingredient.readyWeight || '-'}</div>
            <div className="ingredient-cost">{ingredient.cost || '-'}</div>
          </div>
        ))}
      </div>
      <button className="add-ingredient" onClick={handleAdd}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
      {showModal && (
        <IngredientModal
          ingredient={selectedIngredient}
          onClose={() => setShowModal(false)}
          onSave={handleSave}
          onDelete={handleDelete}
          warehouseIngredients={warehouseIngredients}
        />
      )}
      <div className="ingredients-totals">
        <div className="total-row">
          <span>Bес:</span>
          <span>{calculateTotalWeight()} г</span>
        </div>
        <div className="total-row">
          <span>Cтоимость:</span>
          <span>{calculateTotalCost()} ₽</span>
        </div>
      </div>
    </div>
  );
}

export default IngredientsList;