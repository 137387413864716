// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
apiKey: "AIzaSyCaO4Ja_ZS8Sk7YhQf4mwGGUcteRm0nCAU",
authDomain: "napdf-b27ff.firebaseapp.com",
projectId: "napdf-b27ff",
storageBucket: "napdf-b27ff.firebasestorage.app",
messagingSenderId: "176322007436",
appId: "1:176322007436:web:f147855da9d8a4d32246a9",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, createUserWithEmailAndPassword, signInWithEmailAndPassword };




