import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import RecipeModal from '../components/RecipeModal';
import './SharedRecipesList.css'; // Стили для страницы

function SharedRecipesList() {
  const [sharedRecipes, setSharedRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null); // Инициализируем как null
  const [isUserLoaded, setIsUserLoaded] = useState(false); // Флаг для проверки загрузки пользователя
  const [selectedCategory, setSelectedCategory] = useState('all'); // Состояние для выбранной категории

  // Пример загрузки данных пользователя (замените на вашу логику)
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const userDoc = await getDoc(doc(db, 'users', 'userId')); // Замените 'userId' на реальный ID пользователя
      if (userDoc.exists()) {
        setCurrentUser(userDoc.data());
      } else {
        setCurrentUser({ name: 'Бэтмэн', avatar: '/default-avatar.jpg' }); // Значение по умолчанию
      }
      setIsUserLoaded(true); // Устанавливаем флаг, что пользователь загружен
    };

    fetchCurrentUser();
  }, []);

  const fetchSharedRecipes = async () => {
    try {
      const sharedRecipesSnapshot = await getDocs(collection(db, 'sharedRecipes'));
      const sharedRecipesList = await Promise.all(sharedRecipesSnapshot.docs.map(async (doc) => {
        const recipeData = doc.data();
        const totalWeight = recipeData.ingredients.reduce((sum, ing) => sum + parseFloat(ing.netto), 0);
        const totalCost = recipeData.ingredients.reduce((sum, ing) => sum + parseFloat(ing.cost), 0);

        // Логирование для проверки категории
        console.log("Recipe category:", recipeData.category);

        return {
          id: doc.id,
          ...recipeData,
          totalWeight,
          totalCost
        };
      }));
      setSharedRecipes(sharedRecipesList);
      setFilteredRecipes(sharedRecipesList); // Изначально показываем все рецепты
    } catch (error) {
      console.error("Error fetching shared recipes:", error);
    }
  };

  useEffect(() => {
    fetchSharedRecipes();
  }, []);

  // Фильтрация рецептов по категории
  useEffect(() => {
    if (selectedCategory === 'all') {
      setFilteredRecipes(sharedRecipes);
    } else {
      const filtered = sharedRecipes.filter(recipe => recipe.category === selectedCategory);
      console.log("Filtered recipes:", filtered); // Логирование для проверки фильтрации
      setFilteredRecipes(filtered);
    }
  }, [selectedCategory, sharedRecipes]);

  const handleLike = async (id) => {
    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        await updateDoc(sharedRecipeRef, {
          likes: recipeData.likes + 1
        });
        fetchSharedRecipes();
      }
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const handleDislike = async (id) => {
    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        await updateDoc(sharedRecipeRef, {
          dislikes: recipeData.dislikes + 1
        });
        fetchSharedRecipes();
      }
    } catch (error) {
      console.error("Error updating dislikes:", error);
    }
  };

  const handleView = async (id) => {
    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        navigate(`/edit/${id}`, { state: { recipe: recipeData } });
      } else {
        console.error("Recipe does not exist in Firestore");
      }
    } catch (error) {
      console.error("Error fetching shared recipe:", error);
    }
  };

  const handleCommentSubmit = async (comment) => {
    if (!selectedRecipe) return;

    const wordCount = comment.split(' ').length;

    if (wordCount < 5 || wordCount > 40) {
      alert('Комментарий должен содержать от 5 до 40 слов.');
      return;
    }

    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', selectedRecipe.id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        const newComment = {
          id: Date.now(),
          authorName: currentUser?.name || 'Бэтмэн',
          authorAvatar: currentUser?.avatar || '/default-avatar.jpg',
          text: comment,
          likes: 0,
          dislikes: 0,
          timestamp: new Date().toISOString(),
        };
        await updateDoc(sharedRecipeRef, {
          comments: [...(recipeData.comments || []), newComment],
        });
        fetchSharedRecipes();
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleCommentLike = async (commentId) => {
    if (!selectedRecipe) return;

    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', selectedRecipe.id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        const updatedComments = recipeData.comments.map(comment =>
          comment.id === commentId ? { ...comment, likes: comment.likes + 1 } : comment
        );
        await updateDoc(sharedRecipeRef, {
          comments: updatedComments,
        });
        fetchSharedRecipes();
      }
    } catch (error) {
      console.error("Error updating comment likes:", error);
    }
  };

  const handleCommentDislike = async (commentId) => {
    if (!selectedRecipe) return;

    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', selectedRecipe.id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        const updatedComments = recipeData.comments.map(comment =>
          comment.id === commentId ? { ...comment, dislikes: comment.dislikes + 1 } : comment
        );
        await updateDoc(sharedRecipeRef, {
          comments: updatedComments,
        });
        fetchSharedRecipes();
      }
    } catch (error) {
      console.error("Error updating comment dislikes:", error);
    }
  };

  return (
    <div className="shared-recipes-container">
      <div className="category-filter">
        <label>
          Фильтр:
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="all">Все</option>
            <option value="Завтраки">Завтраки</option>
            <option value="Горячие блюда">Горячие блюда</option>
            <option value="Холодные блюда">Холодные блюда</option>
            <option value="Горячие закуски">Горячие закуски</option>
            <option value="Холодные закуски">Холодные закуски</option>
            <option value="Салаты">Салаты</option>
            <option value="Соусы">Соусы</option>
            <option value="Мангал">Мангал</option>
            <option value="Десерты">Десерты</option>
            <option value="Мини закуски">Мини закуски</option>
            <option value="Полуфабрикаты">Полуфабрикаты</option>
          </select>
        </label>
      </div>
      <div className="shared-recipes-grid">
      {filteredRecipes.map(recipe => (
  <div key={recipe.id} className="shared-recipe-card">
    <img
      src={recipe.slides[0] || '/default-image.jpg'}
      alt={recipe.name}
      className="recipe-image"
      onClick={() => setSelectedRecipe(recipe)}
    />
    <img src={recipe.authorAvatar || '/default-avatar.jpg'} alt={recipe.authorName} className="user-avatar" />
    <div className='shared-recepie-content'>
      <h2>{recipe.name}</h2>
      <div>Автор: {recipe.authorName}</div>
      <div>Место: {recipe.authorPosition}</div>
      <div className='share-recipe-card-total'>
        <div>{recipe.totalWeight} гр</div>
        <div>{recipe.totalCost} руб.</div>
      </div>
    </div>
    <div className="share-recipe-actions">
      <button onClick={() => handleView(recipe.id)}>
        <FontAwesomeIcon icon={faEye} /> Посмотреть
      </button>
      <button onClick={() => handleLike(recipe.id)}>
        <FontAwesomeIcon icon={faThumbsUp} /> {recipe.likes}
      </button>
      <button onClick={() => handleDislike(recipe.id)}>
        <FontAwesomeIcon icon={faThumbsDown} /> {recipe.dislikes}
      </button>
      <div>Отзывы: {recipe.comments?.length || 0}</div>
    </div>
    {/* Отображение видео */}
    {recipe.videos?.map((videoUrl, index) => (
      <video key={index} controls width="100%">
        <source src={videoUrl} type="video/mp4" />
        Ваш браузер не поддерживает видео.
      </video>
    ))}
  </div>
))}
      </div>
      {isUserLoaded && selectedRecipe && (
        <RecipeModal
          recipe={selectedRecipe}
          onClose={() => setSelectedRecipe(null)}
          onCommentSubmit={handleCommentSubmit}
          onCommentLike={handleCommentLike}
          onCommentDislike={handleCommentDislike}
          currentUser={currentUser}
        />
      )}
   

    </div>
  );
}

export default SharedRecipesList;
