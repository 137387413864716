import React, { useState, useEffect } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { auth } from './firebase';
import Auth from './components/Auth/Auth';
import Dashboard from './pages/Dashboard';
import RecipesList from './pages/RecipesList';
import RecipeEditor from './pages/RecipeEditor';
import Warehouse from './pages/Warehouse';
import SharedRecipesList from './pages/SharedRecipesList';
import { getRecipes } from './firebaseFunctions'; // Import getRecipes

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        setIsAuthenticated(true);
        localStorage.setItem('user', JSON.stringify(user)); // Сохраняем пользователя в localStorage
        fetchAndStoreRecipes(user.uid); // Fetch and store recipes upon login
      } else {
        setCurrentUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('user'); // Удаляем пользователя из localStorage
      }
    });

    // Восстановление состояния из localStorage
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setCurrentUser(JSON.parse(savedUser));
      setIsAuthenticated(true);
    }

    return () => unsubscribe();
  }, []);

  const fetchAndStoreRecipes = async (userId) => {
    try {
      const recipes = await getRecipes();
      localStorage.setItem('recipes', JSON.stringify(recipes)); // Store recipes in localStorage
    } catch (error) {
      console.error("Error fetching and storing recipes:", error);
    }
  };

  const handleLogin = (user) => {
    setCurrentUser(user);
    setIsAuthenticated(true);
    localStorage.setItem('user', JSON.stringify(user)); // Сохраняем пользователя в localStorage
    fetchAndStoreRecipes(user.uid); // Fetch and store recipes upon login
  };

  const handleLogout = () => {
    auth.signOut();
    setCurrentUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem('user'); // Удаляем пользователя из localStorage
    localStorage.removeItem('recipes'); // Удаляем рецепты из localStorage
  };

  return (
    <HashRouter basename="/">
      <AuthProvider>
        <Routes>
          <Route
            path="/login"
            element={
              !isAuthenticated ? (
                <Auth
                  onLogin={handleLogin}
                  onRegisterSuccess={(user) => {
                    setCurrentUser(user);
                    setIsAuthenticated(true);
                  }}
                />
              ) : (
                <Navigate to="/recipes" />
              )
            }
          />
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Dashboard user={currentUser} onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" />
              )
            }
          >
            <Route path="recipes" element={<RecipesList />} />
            <Route path="edit/:id" element={<RecipeEditor />} />
            <Route path="warehouse" element={<Warehouse />} />
            <Route path="shared-recipes" element={<SharedRecipesList />} />
          </Route>
        </Routes>
      </AuthProvider>
    </HashRouter>
  );
}

export default App;
