import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import './RecipeModal.css'; // Стили для модального окна

const RecipeModal = ({ recipe, onClose, onCommentSubmit, onCommentLike, onCommentDislike, currentUser }) => {
  const [comment, setComment] = useState('');
  const [sortBy, setSortBy] = useState('newest'); // Фильтр по новизне
  const [commentError, setCommentError] = useState(false); // Состояние для ошибки комментария

  const handleCommentSubmit = () => {
    const wordCount = comment.split(' ').length;

    if (wordCount < 5 || wordCount > 40) {
      setCommentError(true);
      return;
    }

    setCommentError(false);
    onCommentSubmit(comment, currentUser);
    setComment('');
    onClose(); // Закрываем модальное окно после отправки комментария
  };

  // Сортировка комментариев
  const sortedComments = recipe.comments?.slice().sort((a, b) => {
    if (sortBy === 'newest') {
      return new Date(b.timestamp) - new Date(a.timestamp);
    } else if (sortBy === 'likes') {
      return b.likes - a.likes;
    }
    return 0;
  }) || [];

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>{recipe.name}</h2>
        <img src={recipe.slides[0] || '/default-image.jpg'} alt={recipe.name} className="modal-recipe-image" />
        <div className="recipe-details">
          <p style={{ color: 'gold' }}>Автор: {recipe.authorName}</p>
          <p>Место: {recipe.authorPosition}</p>
          <p>Вес: {recipe.totalWeight} гр</p>
          <p>Стоимость: {recipe.totalCost} руб.</p>
        </div>
        <div className="comments-section">
          <h3>Комментарии ({recipe.comments?.length || 0})</h3>
          <div className="comment-filters">
            <label>
              <input
                type="radio"
                name="sortBy"
                value="newest"
                checked={sortBy === 'newest'}
                onChange={() => setSortBy('newest')}
              />
              Сначала новые
            </label>
            <label>
              <input
                type="radio"
                name="sortBy"
                value="likes"
                checked={sortBy === 'likes'}
                onChange={() => setSortBy('likes')}
              />
              По лайкам
            </label>
          </div>
          {sortedComments.map((comment, index) => (
            <div key={index} className="comment">
              <img src={comment?.authorAvatar || '/default-avatar.jpg'} alt={comment?.authorName} className="comment-avatar" />
              <div>
                <p style={{ 
                  color: comment?.authorName === recipe.authorName ? 'gold' : 'inherit',
                  textShadow: comment?.authorName === recipe.authorName ? '0 0 2px white, 0 0 5px white' : 'none',
                  background: comment?.authorName === recipe.authorName ? 'linear-gradient(to right, rgba(255,255,255,0.2), rgba(255,255,255,0))' : 'none'
                }}>
                  {comment?.authorName || 'Гость'}
                </p>
                <p>{comment?.text}</p>
                <div className="comment-actions">
                  <button onClick={() => onCommentLike(comment.id)}>
                    <FontAwesomeIcon icon={faThumbsUp} /> {comment?.likes || 0}
                  </button>
                  <button onClick={() => onCommentDislike(comment.id)}>
                    <FontAwesomeIcon icon={faThumbsDown} /> {comment?.dislikes || 0}
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="add-comment">
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className={commentError ? 'comment-input-error' : ''}
              placeholder="Добавьте комментарий (от 5 до 40 слов)"
            />
            {commentError && <p className="error-message">Комментарий должен содержать от 5 до 40 слов.</p>}
            <button onClick={handleCommentSubmit}>Отправить</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeModal;
