import React, { useState, useEffect } from 'react';
import './Modals.css';
import imageCompression from 'browser-image-compression'; // Импортируем библиотеку для сжатия

function ProfileModal({ user, onClose, onSave }) {
  const [avatar, setAvatar] = useState(user.avatar || '');
  const [name, setName] = useState(user.name || '');
  const [position, setPosition] = useState(user.position || '');

  useEffect(() => {
    // Обновляем состояние при изменении пропсов
    setAvatar(user.avatar || '');
    setName(user.name || '');
    setPosition(user.position || '');
  }, [user]);

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Сжимаем изображение
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.5, // Максимальный размер файла в мегабайтах
          maxWidthOrHeight: 200, // Максимальная ширина или высота
          useWebWorker: true, // Использовать веб-воркер для сжатия
        });

        // Преобразуем сжатое изображение в Data URL
        const reader = new FileReader();
        reader.onloadend = () => {
          setAvatar(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ avatar, name, position });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Редактировать профиль</h2>
        <form onSubmit={handleSubmit}>
          <div className="avatar-input">
            <img src={avatar || 'https://via.placeholder.com/150'} alt="Аватар" className="avatar-preview" />
            <input
              type="file"
              accept="image/*"
              onChange={handleAvatarChange}
              className="avatar-file-input"
            />
          </div>
          <input
            type="text"
            placeholder="Имя"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Должность"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
          <div className="modal-buttons">
            <button type="submit">Сохранить</button>
            <button type="button" onClick={onClose}>Закрыть</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfileModal;
