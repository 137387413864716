import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from '../firebase';
import ProfileModal from '../components/Modals/ProfileModal';
import './Dashboard.css';
import '../App.css';


function Dashboard({ user, onLogout }) {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        try {
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const profileData = userDocSnap.data();
            setUserProfile(profileData);
            localStorage.setItem('userProfile', JSON.stringify(profileData)); // Сохранение в localStorage
          } else {
            setUserProfile({});
            localStorage.removeItem('userProfile'); // Удаление из localStorage
          }
        } catch (error) {
          setError("Ошибка при загрузке профиля: " + error.message);
        }
      } else {
        // Если пользователь не авторизован, очищаем профиль
        setUserProfile({});
        localStorage.removeItem('userProfile'); // Удаление из localStorage
      }
    });

    // Восстановление профиля из localStorage
    const savedProfile = localStorage.getItem('userProfile');
    if (savedProfile) {
      setUserProfile(JSON.parse(savedProfile));
    }

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    onLogout();
    navigate('/login');
    localStorage.removeItem('userProfile'); // Удаление из localStorage
  };

  const handleSaveProfile = async (profileData) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      try {
        await setDoc(userDocRef, {
          avatar: profileData.avatar,
          name: profileData.name,
          position: profileData.position
        }, { merge: true });

        setUserProfile(profileData);
        setShowProfileModal(false);
        localStorage.setItem('userProfile', JSON.stringify(profileData)); // Сохранение в localStorage
      } catch (error) {
        setError("Ошибка при сохранении профиля: " + error.message);
      }
    }
  };
  const createSnowflakes = () => {
    const snowflakes = [];
    for (let i = 0; i < 50; i++) {
      const style = {
        left: `${Math.random() * 100}vw`,
        animationDuration: `${Math.random() * 3 + 2}s`,
        animationDelay: `${Math.random() * 2}s`,
        width: `${Math.random() * 5 + 5}px`,
        height: `${Math.random() * 5 + 5}px`,
      };
      snowflakes.push(<div key={i} className="snowflake" style={style}></div>);
    }
    return snowflakes;
  };
  const createStars = () => {
    const stars = [];
    for (let i = 0; i < 100; i++) {
      const style = {
        top: `${Math.random() * 100}vh`,
        left: `${Math.random() * 100}vw`,
        animationDuration: `${Math.random() * 2 + 1}s`,
        animationDelay: `${Math.random() * 2}s`,
      };
      stars.push(<div key={i} className="star" style={style}></div>);
    }
    return stars;
  };


  return (
    
    <div className="dashboard">

           {/* Снегопад */}
           <div className="snowflakes">{createSnowflakes()}</div>

{/* Мерцание звезд */}
<div className="stars">{createStars()}</div>

      <header className="dashboard-header">
        <div className="user-profile">
          <img 
            src={userProfile.avatar || '/default-avatar.png'} 
            alt="Фото профиля" 
          />
          <h2>{userProfile.name || 'Имя не указано'}</h2>
          <p>Место работы: {userProfile.position || 'Должность не указана'}</p>
          <button onClick={() => setShowProfileModal(true)} className="button edit-profile-btn">
            <i className="fas fa-pencil-alt" >Edit</i> 
          </button>
        <button onClick={handleLogout} className="button">Exit</button>
        </div>
        
      </header>
      <div className="dashboard-content">
        {/* Здесь будет основной контент дашборда */}
      </div>
      {/* Добавляем надпись "Это Beta Test" */}
      <div className="beta-test-label">Beta Test</div>


      <nav className="dashboard-nav">
        <Link to="/recipes" className="nav-link">
          <i className="fas fa-book"></i>
          <span>Мои рецепты</span>
        </Link>
        {/* <Link to="/edit/new" className="nav-link" rel="noreferrer">
          <i className="fas fa-plus"></i>
          <span>Создать рецепт</span>
        </Link> */}
        <Link to="/warehouse" className="nav-link" rel="noreferrer">
          <i className="fas fa-warehouse"></i>
          <span>Склад</span>
        </Link>
        <Link to="/shared-recipes" className="nav-link" rel="noreferrer">
          <i className="fas fa-share-alt"></i>
          <span>Бездна</span>
        </Link>
      </nav>
 
      <main className="dashboard-content">
        <Outlet />
      </main>

      {showProfileModal && (
        <ProfileModal
          user={userProfile}
          onClose={() => setShowProfileModal(false)}
          onSave={handleSaveProfile}
        />
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default Dashboard;
