import { db, auth } from './firebase';
import { collection, addDoc, getDocs, query, where, Timestamp, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";

export const saveRecipe = async (recipe) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated");
    }
    console.log("Saving recipe with data:", recipe); // Логирование

    const recipeRef = doc(db, 'recipes', recipe.id);
    const recipeDoc = await getDoc(recipeRef);

    if (recipeDoc.exists()) {
      // Обновляем существующий рецепт
      await setDoc(recipeRef, {
        ...recipe,
        updatedAt: Timestamp.now()
      }, { merge: true });
      console.log("Document updated with ID: ", recipe.id);
    } else {
      // Создаем новый рецепт
      await setDoc(recipeRef, {
        ...recipe,
        authorId: user.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });
      console.log("Document written with ID: ", recipe.id);
    }
    return recipe.id;
  } catch (e) {
    console.error("Error adding/updating document: ", e);
    throw e;
  }
};

export const shareRecipe = async (recipe) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated");
    }

    const userDocRef = doc(db, "users", user.uid);
    const userDocSnap = await getDoc(userDocRef);
    const userData = userDocSnap.data();

    // Используем переданный id для создания документа в sharedRecipes
    const sharedRecipeRef = doc(db, 'sharedRecipes', recipe.id);
    await setDoc(sharedRecipeRef, {
      ...recipe,
      authorId: user.uid,
      authorAvatar: userData.avatar,
      authorName: userData.name,
      authorPosition: userData.position,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      likes: 0,
      dislikes: 0,
      saves: 0
    });
    console.log("Shared recipe written with ID:", recipe.id);

    alert("Рецепт улетел в бездну", recipe.id);
    return recipe.id;
  } catch (e) {
    console.error("Error sharing recipe:", e);
    throw e;
  }
};

export const getRecipes = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const q = query(collection(db, "recipes"), where("authorId", "==", user.uid));
  const querySnapshot = await getDocs(q);
  const recipes = [];
  querySnapshot.forEach((doc) => {
    recipes.push({ id: doc.id, ...doc.data() });
  });
  console.log("Fetched recipes:", recipes);
  return recipes;
};

export const deleteRecipe = async (recipeId) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated");
    }

    const recipeRef = doc(db, 'recipes', recipeId);
    await deleteDoc(recipeRef);
    console.log("Document deleted with ID: ", recipeId);
  } catch (e) {
    console.error("Error deleting document: ", e);
    throw e;
  }
};
export const saveRows = async (rows) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const userRef = doc(db, 'users', user.uid);
  await setDoc(userRef, { rows }, { merge: true });
};

export const getRows = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) {
    return userDoc.data().rows || 1; // Возвращаем количество рядов, по умолчанию 1
  }
  return 1;
};
// Функция для получения склада пользователя
export const getWarehouse = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const warehouseRef = doc(db, "warehouse", user.uid);
  const warehouseSnap = await getDoc(warehouseRef);
  if (warehouseSnap.exists()) {
    return warehouseSnap.data().categories || [];
  } else {
    return [];
  }
};

// Функция для сохранения склада пользователя
export const saveWarehouse = async (categories) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const warehouseRef = doc(db, "warehouse", user.uid);
  await setDoc(warehouseRef, { userId: user.uid, categories }, { merge: true });
};

// Функция для удаления категории или ингредиента
export const deleteWarehouseItem = async (itemId, isCategory) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const warehouseRef = doc(db, "warehouse", user.uid);
  const warehouseSnap = await getDoc(warehouseRef);
  if (warehouseSnap.exists()) {
    const warehouseData = warehouseSnap.data();
    let updatedCategories;
    if (isCategory) {
      updatedCategories = warehouseData.categories.filter(cat => cat.id !== itemId);
    } else {
      updatedCategories = warehouseData.categories.map(cat => ({
        ...cat,
        ingredients: cat.ingredients.filter(ing => ing.id !== itemId)
      }));
    }

    await setDoc(warehouseRef, { categories: updatedCategories }, { merge: true });
  }
};