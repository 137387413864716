import React, { useState, useEffect } from 'react';
import './Modals.css'

function IngredientModal({ ingredient, onClose, onSave, onDelete, warehouseIngredients }) {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    netto: '',
    waste: '', // процент горячего отхода
    coldWaste: '' // процент холодного отхода из склада
  });
  const [suggestions, setSuggestions] = useState([]);
  const [isInWarehouse, setIsInWarehouse] = useState(true);

  useEffect(() => {
    if (ingredient) {
      setFormData(ingredient);
      setIsInWarehouse(warehouseIngredients.some(item => item.name === ingredient.name));
    }
  }, [ingredient, warehouseIngredients]);

  const calculateBrutto = (netto, coldWaste) => {
    if (!netto || !coldWaste || coldWaste >= 100) return '';
    return (netto / (1 - coldWaste / 100)).toFixed(0);
  };

  // Добавляем функцию расчета веса готового продукта
  const calculateReadyWeight = (netto, waste) => {
    if (!netto || !waste) return '';
    return Math.round(netto * (100 - waste) / 100);
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setFormData(prevData => ({
      ...prevData,
      [field]: value,
    }));
    if (field === 'name') {
      const filteredSuggestions = warehouseIngredients.filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setIsInWarehouse(filteredSuggestions.length > 0);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData(prevData => ({
      ...prevData,
      name: suggestion.name,
      coldWaste: suggestion.coldWaste,
      waste: ''
    }));
    setSuggestions([]);
    setIsInWarehouse(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name && formData.netto && formData.waste) {
      onSave({
        ...formData,
        id: formData.id || Date.now(),
        brutto: calculateBrutto(formData.netto, formData.waste),
        readyWeight: calculateReadyWeight(formData.netto, formData.waste), // Добавляем расчет веса готового продукта
        isInWarehouse: isInWarehouse
      });
    } else {
      alert('Пожалуйста, заполните все поля');
    }
  };

  const handleDelete = () => {
    if (ingredient && ingredient.id) {
      onDelete(ingredient.id);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Название ингредиента"
              value={formData.name}
              onChange={(e) => handleInputChange(e, 'name')}
              style={{ backgroundColor: isInWarehouse ? 'white' : '#f0f0f0' }}
            />
            {suggestions.length > 0 && (
              <ul className="suggestions">
                {suggestions.map(item => (
                  <li key={item.id} onClick={() => handleSuggestionClick(item)}>
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <input
            type="number"
            placeholder="Нетто (г)"
            value={formData.netto}
            onChange={(e) => handleInputChange(e, 'netto')}
          />
          <input
            type="number"
            placeholder="% горячего отхода"
            value={formData.waste}
            onChange={(e) => handleInputChange(e, 'waste')}
          />
          <input
            type="text"
            placeholder="Готовый продукт (г)"
            value={calculateReadyWeight(formData.netto, formData.waste)}
            disabled
            style={{ backgroundColor: '#f0f0f0' }}
          />
          <div className="modal-buttons">
            <button type="submit">Сохранить</button>
            {ingredient && <button type="button" onClick={handleDelete}>Удалить</button>}
            <button type="button" onClick={onClose}>Закрыть</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default IngredientModal;