import React, { useState, useEffect } from 'react';
import './Modals.css'

function WarehouseIngredientModal({ ingredient, onClose, onSave, onDelete }) {
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    coldWaste: '' // оставляем только процент холодной обработки
  });

  useEffect(() => {
    if (ingredient) {
      setFormData(ingredient);
    }
  }, [ingredient]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      ...formData,
      id: ingredient ? ingredient.id : Date.now()
    });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{ingredient ? 'Редактировать ингредиент' : 'Добавить ингредиент'}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Название ингредиента"
            value={formData.name}
            onChange={(e) => setFormData({...formData, name: e.target.value})}
            required
          />
          <input
            type="number"
            placeholder="Цена за 1 кг"
            value={formData.price}
            onChange={(e) => setFormData({...formData, price: e.target.value})}
            required
          />
          <input
            type="number"
            placeholder="% отхода при холодной обработке"
            value={formData.coldWaste}
            onChange={(e) => setFormData({...formData, coldWaste: e.target.value})}
            required
          />
          <div className="modal-buttons">
            <button type="submit">Сохранить</button>
            {ingredient && <button type="button" onClick={() => onDelete(ingredient.id)}>Удалить</button>}
            <button type="button" onClick={onClose}>Закрыть</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WarehouseIngredientModal;