import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './RecipeCards.css';
import { getRecipes, deleteRecipe, shareRecipe, saveRows } from '../firebaseFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../firebase'; // Импортируем auth для проверки авторизации
import './RecipesList.css'
import { getDocs, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Убедитесь, что путь к файлу firebase.js правильный

function RecipesList() {
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all'); // Состояние для выбранной категории
  const [rows, setRows] = useState(() => {
    // Получаем количество рядов из локальной памяти
    const savedRows = localStorage.getItem('rows');
    return savedRows ? parseInt(savedRows, 10) : 1;
  });
  const [availableRows, setAvailableRows] = useState(1); // Состояние для доступных рядов
  const [isPaid, setIsPaid] = useState(false); // Состояние для проверки оплаты
  const [rowsToAdd, setRowsToAdd] = useState(0); // Количество строк, которые нужно добавить
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для управления модальным окном
  const [selectedRowsToPay, setSelectedRowsToPay] = useState(1); // Состояние для выбранного количества строк для оплаты
  const [showAddRowButton, setShowAddRowButton] = useState(false); // Состояние для отображения кнопки "Добавить строку"
  const [showPaymentButton, setShowPaymentButton] = useState(true); // Состояние для отображения кнопки "Оплатить доступ"

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const recipesSnapshot = await getDocs(collection(db, 'recipes'));
        const recipesList = recipesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRecipes(recipesList);
        setFilteredRecipes(recipesList); // Изначально показываем все рецепты
      } catch (error) {
        console.error("Error fetching recipes:", error);
      }
    };

    // Восстановление рецептов из localStorage
    const savedRecipes = localStorage.getItem('recipes');
    if (savedRecipes) {
      setRecipes(JSON.parse(savedRecipes));
    } else {
      fetchRecipes();
    }

    // Получаем данные о доступных рядах и статусе оплаты
    const fetchUserRows = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setRows(userData.rows || 1); // Обновляем количество рядов
          setAvailableRows(userData.availableRows || 1); // Обновляем доступные ряды
          setIsPaid(userData.isPaid || false); // Обновляем состояние оплаты
          setRowsToAdd(userData.rowsToAdd || 0); // Обновляем количество строк для добавления
        }
      }
    };

    fetchUserRows();
  }, []);

  useEffect(() => {
    if (selectedCategory === 'all') {
      setFilteredRecipes(recipes);
    } else {
      const filtered = recipes.filter(recipe => recipe.category === selectedCategory);
      setFilteredRecipes(filtered);
    }
  }, [selectedCategory, recipes]);

  const handleDeleteRecipe = async (id) => {
    try {
      await deleteRecipe(id);
      const updatedRecipes = recipes.filter(recipe => recipe.id !== id);
      setRecipes(updatedRecipes);
      localStorage.setItem('recipes', JSON.stringify(updatedRecipes)); // Обновление localStorage
    } catch (error) {
      console.error("Error deleting recipe:", error);
    }
  };

  useEffect(() => {
    // Сохраняем количество рядов в локальной памяти
    localStorage.setItem('rows', rows);

    // Проверяем, авторизован ли пользователь перед вызовом saveRows
    if (auth.currentUser) {
      saveRows(rows).catch(error => {
        console.error("Error saving rows to Firestore:", error);
      });
    }
  }, [rows]);

  const handleAddRow = async () => {
    if (rowsToAdd > 0) {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, {
          rows: rows + rowsToAdd, // Увеличиваем количество рядов
          rowsToAdd: 0 // Сбрасываем количество строк для добавления
        });
        setRows(rows + rowsToAdd); // Обновляем состояние рядов
        setRowsToAdd(0); // Сбрасываем количество строк для добавления
        setShowAddRowButton(false); // Скрываем кнопку "Добавить строку"
        setShowPaymentButton(true); // Показываем кнопку "Оплатить доступ"
      }
    } else {
      alert('Чтобы увеличить рюкзак, оплатите доступ.');
    }
  };

  const handlePayment = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert('Пожалуйста, войдите в систему.');
      return;
    }

    const userDocRef = doc(db, 'users', user.uid);
    await updateDoc(userDocRef, {
      isPaid: true, // Устанавливаем статус оплаты
      rowsToAdd: selectedRowsToPay // Сохраняем количество строк для добавления
    });

    setIsPaid(true); // Обновляем состояние оплаты
    setRowsToAdd(selectedRowsToPay); // Обновляем количество строк для добавления
    setIsModalOpen(false); // Закрываем модальное окно
    setShowPaymentButton(false); // Скрываем кнопку "Оплатить доступ"
    setShowAddRowButton(true); // Показываем кнопку "Добавить строку"
    alert(`Оплата прошла успешно! Теперь вы можете добавить ${selectedRowsToPay} строк.`);
  };

  const handleCellClick = (index) => {
    const recipeIndex = index % 3; // Индекс рецепта в ряду
    if (!recipes[index]) {
      // Если ячейка пустая, переходим к созданию нового рецепта
      navigate('/edit/new');
    }
  };

  console.log(recipes);

  return (
    <div className="recipes-container">
      <div className="category-filter">
        <label>
          Фильтр:
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="all">Все</option>
            <option value="Завтраки">Завтраки</option>
            <option value="Горячие блюда">Горячие блюда</option>
            <option value="Холодные блюда">Холодные блюда</option>
            <option value="Горячие закуски">Горячие закуски</option>
            <option value="Холодные закуски">Холодные закуски</option>
            <option value="Салаты">Салаты</option>
            <option value="Соусы">Соусы</option>
            <option value="Мангал">Мангал</option>
            <option value="Десерты">Десерты</option>
            <option value="Мини закуски">Мини закуски</option>
            <option value="Полуфабрикаты">Полуфабрикаты</option>
          </select>
        </label>
      </div>

     

      <div className="recipes-grid">
        {Array.from({ length: rows * 3 }).map((_, index) => {
          const recipe = filteredRecipes[index]; // Используем отфильтрованные рецепты
          return (
            <div
              key={index}
              className={recipe ? 'recipe-card' : 'empty-cell'}
              onClick={() => handleCellClick(index)}
            >
              {recipe ? (
                <>
                  {/* Используем первое изображение из слайдера или изображение по умолчанию */}
                  <img
                    src={recipe.slides && recipe.slides[0] ? recipe.slides[0] : '/default-image.jpg'}
                    alt={recipe.name}
                    className="recipe-image"
                  />
                  <div className="recipe-content">
                    <h2>{recipe.name}</h2>
                    <p className="recipe-description">{recipe.description.split(' ').slice(0, 10).join(' ')}</p>
                    <div className="recipe-details">
                      <div> {recipe.totalWeight} гр</div>
                      <div> {recipe.totalCost} руб</div>
                    </div>
                    <div className="recipe-actions">
                      <button onClick={() => handleDeleteRecipe(recipe.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      <Link to={`/edit/${recipe.id}`} className="recipe-actions-action" role="button">
                        <FontAwesomeIcon icon={faEdit} />
                      </Link>
                      <button onClick={() => shareRecipe(recipe)}>
                        <FontAwesomeIcon icon={faShareAlt} />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="empty-cell"></div>
              )}
            </div>
            
          );
        })}
      </div>
      {showPaymentButton && (
        <button className="payment-button-rows" onClick={() => setIsModalOpen(true)}>
          Увеличить размер рюкзака ?
        </button>
      )}

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Выберите количество строк для оплаты</h2>
            <div className="modal-buttons">
              {[1, 3, 5, 10].map((num) => (
                <button
                  key={num}
                  className={selectedRowsToPay === num ? 'selected' : ''}
                  onClick={() => setSelectedRowsToPay(num)}
                >
                  {num} строк
                </button>
              ))}
            </div>
            <button className="modal-payment-button" onClick={handlePayment}>
              Оплатить
            </button>
            <button className="modal-close-button" onClick={() => setIsModalOpen(false)}>
              Закрыть
            </button>
          </div>
        </div>
      )}

      {showAddRowButton && (
        <button
          className="add-row-button"
          onClick={handleAddRow}
          disabled={rowsToAdd === 0} // Отключаем кнопку, если нет строк для добавления
        >
          Увеличить размер рюкзака на {rowsToAdd} 
        </button>
      )}
    </div>
  );
}

export default RecipesList;
