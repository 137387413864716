import React, { useState, useEffect } from 'react';
import './SaveContainer.css';



const SaveContainer = ({ recipeName, setRecipeName, onSave }) => {
  const categories = [
    "Завтраки", "Горячие блюда", "Холодные блюда", "Горячие закуски",
    "Холодные закуски", "Салаты", "Соусы", "Мангал", "Десерты",
    "Мини закуски", "Полуфабрикаты"
  ];

  const [selectedCategory, setSelectedCategory] = React.useState(categories[0]);

  const handleSave = () => {
    onSave(selectedCategory); // Передаем выбранную категорию в функцию onSave
  };

  return (
    <div className="save-container">
      <input
        type="text"
        value={recipeName}
        onChange={(e) => setRecipeName(e.target.value)}
        placeholder="Название рецепта"
      />
      <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
        {categories.map((category, index) => (
          <option key={index} value={category}>{category}</option>
        ))}
      </select>
      <button onClick={handleSave}>Сохранить</button>
    </div>
  );
};




export default SaveContainer;