import React, { useState, useEffect } from 'react';
import { getWarehouse, saveWarehouse, deleteWarehouseItem } from '../firebaseFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import WarehouseIngredientModal from '../components/Modals/WarehouseIngredientModal';
import CategoryModal from '../components/Modals/CategoryModal';
import './Warehouse.css';

function Warehouse() {
  const [categories, setCategories] = useState([]);
  const [showIngredientModal, setShowIngredientModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchWarehouse = async () => {
      try {
        const warehouseData = await getWarehouse();
        setCategories(warehouseData);
      } catch (error) {
        console.error('Ошибка при загрузке данных склада:', error);
      }
    };

    fetchWarehouse();
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem('warehouseCategories', JSON.stringify(categories));
    } catch (error) {
      console.error('Ошибка при сохранении данных склада:', error);
    }
  }, [categories]);

  const handleAddCategory = async (newCategory) => {
    const updatedCategories = [...categories, { ...newCategory, ingredients: [], isOpen: true }];
    setCategories(updatedCategories);
    await saveWarehouse(updatedCategories);
    setShowCategoryModal(false);
  };

  const handleDeleteCategory = async (categoryId) => {
    const updatedCategories = categories.filter(cat => cat.id !== categoryId);
    setCategories(updatedCategories);
    await deleteWarehouseItem(categoryId, true);
    setShowCategoryModal(false);
  };

  const handleAddIngredient = async (newIngredient) => {
    const updatedCategories = categories.map(cat => 
      cat.id === selectedCategory.id 
        ? { ...cat, ingredients: [...cat.ingredients, newIngredient] }
        : cat
    );
    setCategories(updatedCategories);
    await saveWarehouse(updatedCategories);
    setShowIngredientModal(false);
  };

  const handleEditIngredient = async (editedIngredient) => {
    const updatedCategories = categories.map(cat => ({
      ...cat,
      ingredients: cat.ingredients.map(ing => 
        ing.id === editedIngredient.id ? editedIngredient : ing
      )
    }));
    setCategories(updatedCategories);
    await saveWarehouse(updatedCategories);
    setShowIngredientModal(false);
  };

  const handleDeleteIngredient = async (ingredientId) => {
    const updatedCategories = categories.map(cat => ({
      ...cat,
      ingredients: cat.ingredients.filter(ing => ing.id !== ingredientId)
    }));
    setCategories(updatedCategories);
    await deleteWarehouseItem(ingredientId, false);
    setShowIngredientModal(false);
  };

  const toggleCategory = (categoryId) => {
    setCategories(categories.map(cat => 
      cat.id === categoryId ? { ...cat, isOpen: !cat.isOpen } : cat
    ));
  };

  const handleEditCategory = async (editedCategory) => {
    const updatedCategories = categories.map(cat => 
      cat.id === editedCategory.id ? { ...cat, name: editedCategory.name } : cat
    );
    setCategories(updatedCategories);
    await saveWarehouse(updatedCategories);
    setShowCategoryModal(false);
  };

  const filteredCategories = categories.map(cat => ({
    ...cat,
    ingredients: cat.ingredients.filter(ing => 
      ing.name.toLowerCase().includes(filter.toLowerCase())
    )
  }));

  return (
    <div className="warehouse">
      <input 
        type="text" 
        placeholder="Фильтр ингредиентов" 
        value={filter} 
        onChange={(e) => setFilter(e.target.value)}
        className="filter-input"
      />
      <button onClick={() => setShowCategoryModal(true)} className="add-category-btn">
        Добавить категорию
      </button>
      {filteredCategories.map(category => (
        <div key={category.id} className="category">
          <div className="category-header">
            <FontAwesomeIcon 
              icon={category.isOpen ? faChevronDown : faChevronRight} 
              onClick={() => toggleCategory(category.id)}
            />
            <h2 onClick={() => {
              setSelectedCategory(category);
              setShowCategoryModal(true);
            }}>{category.name}</h2>
          </div>
          {category.isOpen && (
            <div className="category-content">
              <table className="ingredients-table">
                <thead>
                  <tr>
                    <th>Название</th>
                    <th>Цена за 1 кг</th>
                    <th>% холодной обработки</th>
                  </tr>
                </thead>
                <tbody>
                  {category.ingredients.map(ingredient => (
                    <tr key={ingredient.id} onClick={() => {
                      setSelectedIngredient(ingredient);
                      setSelectedCategory(category);
                      setShowIngredientModal(true);
                    }}>
                      <td>{ingredient.name}</td>
                      <td>{ingredient.price} руб.</td>
                      <td>{ingredient.coldWaste}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => {
                setSelectedCategory(category);
                setSelectedIngredient(null);
                setShowIngredientModal(true);
              }} className="add-ingredient-btn">
                <FontAwesomeIcon icon={faPlus} /> 
              </button>
            </div>
          )}
        </div>
      ))}
      {showIngredientModal && (
        <WarehouseIngredientModal
          ingredient={selectedIngredient}
          onClose={() => setShowIngredientModal(false)}
          onSave={selectedIngredient ? handleEditIngredient : handleAddIngredient}
          onDelete={handleDeleteIngredient}
        />
      )}
      {showCategoryModal && (
        <CategoryModal
          category={selectedCategory}
          onClose={() => setShowCategoryModal(false)}
          onSave={selectedCategory ? handleEditCategory : handleAddCategory}
          onDelete={handleDeleteCategory}
        />
      )}
    </div>
  );
}

export default Warehouse;
