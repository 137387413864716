// src/components/Auth/Auth.js
import React, { useState } from 'react';
import { auth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from '../../firebase';
import { sendPasswordResetEmail } from "firebase/auth";

const Auth = ({ onLogin, onRegisterSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      onLogin(userCredential.user);
    } catch (error) {
      alert('Ошибка входа: ' + error.message);
    }
  };

  const handleRegister = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      onRegisterSuccess(userCredential.user);
    } catch (error) {
      alert('Ошибка регистрации: ' + error.message);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Ссылка для сброса пароля отправлена на ваш email.');
      setIsResettingPassword(false);
    } catch (error) {
      alert('Ошибка при отправке ссылки для сброса пароля: ' + error.message);
    }
  };

  return (
    <div>
      {isResettingPassword ? (
        <div>
          <h2>Восстановление пароля</h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handlePasswordReset}>Отправить ссылку для сброса пароля</button>
          <button onClick={() => setIsResettingPassword(false)}>Отмена</button>
        </div>
      ) : (
        <div>
          <h2>Вход</h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleLogin}>Войти</button>
          <button onClick={handleRegister}>Зарегистрироваться</button>
          <button onClick={() => setIsResettingPassword(true)}>Забыли пароль?</button>
        </div>
      )}
    </div>
  );
};

export default Auth;
