import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Slider from '../components/Slider/Slider';
import IngredientsList from '../components/Ingredients/IngredientsList';
import Tabs from '../components/Tabs/Tabs';
import SaveContainer from '../components/SaveContainer/SaveContainer';
import PdfGenerator from '../components/PdfGenerator/PdfGenerator';
import { saveRecipe, getRecipes } from '../firebaseFunctions';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const storage = getStorage();

function RecipeEditor() {
  const [slides, setSlides] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [steps, setSteps] = useState([]);
  const [recipe, setRecipe] = useState('');
  const [recipeName, setRecipeName] = useState('');
  const [videos, setVideos] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]); // Состояние для хранения файлов видео

  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const inputRef = useRef(null);

  useEffect(() => {
    if (id !== 'new') {
      if (location.state && location.state.recipe) {
        const recipe = location.state.recipe;
        setSlides(recipe.slides || []);
        setIngredients(recipe.ingredients || []);
        setSteps(recipe.steps || []);
        setRecipe(recipe.description || '');
        setRecipeName(recipe.name || '');
        setVideos(recipe.videos || []);
      } else {
        getRecipes().then(recipes => {
          const currentRecipe = recipes.find(r => r.id === id);
          if (currentRecipe) {
            setSlides(currentRecipe.slides || []);
            setIngredients(currentRecipe.ingredients || []);
            setSteps(currentRecipe.steps || []);
            setRecipe(currentRecipe.description || '');
            setRecipeName(currentRecipe.name || '');
            setVideos(currentRecipe.videos || []);
          } else {
            console.error("Recipe not found in Firestore");
          }
        }).catch(error => {
          console.error("Error fetching recipe from Firestore:", error);
        });
      }
    }
  }, [id, location]);

  const saveRecipeToFirestore = async (selectedCategory) => {
    if (!recipeName.trim()) {
      alert('Пожалуйста, введите название рецепта');
      return;
    }

    const newRecipeId = Date.now().toString();

    // Вычисляем totalWeight и totalCost
    const totalWeight = ingredients.reduce((sum, ing) => sum + parseFloat(ing.netto || 0), 0);
    const totalCost = ingredients.reduce((sum, ing) => sum + parseFloat(ing.cost || 0), 0);

    // Загрузка видео в Firebase Storage
    const videoUrls = await Promise.all(videoFiles.map(async (videoFile) => {
      const videoRef = ref(storage, `videos/${newRecipeId}/${videoFile.name}`);
      await uploadBytes(videoRef, videoFile);
      return await getDownloadURL(videoRef);
    }));

    const recipeData = {
      id: newRecipeId,
      name: recipeName,
      ingredients: ingredients,
      steps: steps,
      description: recipe,
      slides: slides,
      videos: videoUrls, // Сохраняем ссылки на видео
      totalWeight,
      totalCost,
      category: selectedCategory,
    };

    console.log("Saving recipe with data:", recipeData); // Логирование

    try {
      await saveRecipe(recipeData); // Сохраняем рецепт с новым id
      alert(`Рецепт успешно сохранен с ID: ${newRecipeId}`);
      navigate('/recipes');

      // Обновляем localStorage
      const savedRecipes = JSON.parse(localStorage.getItem('recipes')) || [];
      localStorage.setItem('recipes', JSON.stringify([...savedRecipes, recipeData]));
    } catch (error) {
      console.error('Ошибка при сохранении рецепта:', error);
      alert('Произошла ошибка при сохранении рецепта');
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="editor">
      <Slider slides={slides} setSlides={setSlides} />
      <IngredientsList ingredients={ingredients} setIngredients={setIngredients} />
      <Tabs
        steps={steps}
        setSteps={setSteps}
        recipe={recipe}
        setRecipe={setRecipe}
        videos={videos}
        setVideos={setVideos}
        setVideoFiles={setVideoFiles} // Передаем setVideoFiles в Tabs
      />
      <SaveContainer
        recipeName={recipeName}
        setRecipeName={setRecipeName}
        onSave={saveRecipeToFirestore}
      />
      <PdfGenerator
        recipe={{
          name: recipeName,
          ingredients: ingredients,
          steps: steps,
          description: recipe
        }}
      />
    </div>
  );
}

export default RecipeEditor;
