import { jsPDF } from "jspdf"; 
import 'jspdf-autotable'; 

function PdfGenerator({ recipe }) { 
    const generatePDF = () => { 
        const doc = new jsPDF(); 
        doc.addFont('https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf', 'Roboto', 'normal'); 
        doc.setFont('Roboto'); 

        // Колонтитулы 
        doc.setFontSize(10); 
        doc.text("", 20, 10); 
        doc.text("Утверждаю", doc.internal.pageSize.width - 20, 10, { align: 'right' }); 
        doc.text("Ген. директор ____________", doc.internal.pageSize.width - 20, 15, { align: 'right' }); 

        // Заголовок 
        doc.setFontSize(16); 
        doc.text("Технико-технологическая карта от   " + new Date().toLocaleDateString(), doc.internal.pageSize.width/2, 30, { align: 'center' }); 

        // Название блюда 
        doc.setFontSize(14); 
        doc.text(recipe.name, doc.internal.pageSize.width/2, 40, { align: 'center' }); 

        // Раздел 1 
        doc.setFontSize(12); 
        doc.text("1. Область применения", doc.internal.pageSize.width/2, 50, { align: 'center' }); 
        doc.setFontSize(10); 
        const text1 = `Настоящая технико-технологическая карта распространяется на блюдо (изделие) ${recipe.name} вырабатываемое ИП или ООО и реализуемое в данном заведении.`; 
        const splitText1 = doc.splitTextToSize(text1, 170); 
        doc.text(splitText1, 20, 55); 

        // Раздел 2 
        doc.setFontSize(12); 
        let yPos = 55 + (splitText1.length * 5); 
        doc.text("2. Требования к сырью", 105, yPos,{ align: 'center' }); 
        doc.setFontSize(10); 
        const text2 = "Продовольственное сырье, пищевые продукты и полуфабрикаты, используемые для приготовления данного блюда, должны соответствовать требованиям действующих нормативных и технических документов."; 
        const splitText2 = doc.splitTextToSize(text2, 170); 
        doc.text(splitText2, 20, yPos + 5); 

        // Ингредиенты 
        yPos += splitText2.length * 5 + 10; 
        if (recipe.ingredients && recipe.ingredients.length > 0) { 
            doc.setFontSize(12); 
            doc.text("3. Рецептура", 83, yPos); 
            const ingredientsData = recipe.ingredients.map(ing => [ 
                ing.name, 
                `${parseFloat(ing.brutto).toFixed(2)} г`, 
                `${parseFloat(ing.netto).toFixed(2)} г` 
            ]); 
            const totalNetto = recipe.ingredients.reduce((sum, ing) =>  
                sum + parseFloat(ing.netto), 0 
            ).toFixed(2); 
            doc.autoTable({ 
                startY: yPos + 5, 
                head: [['Наименование сырья', 'Брутто, г', 'Нетто, г']], 
                body: ingredientsData, 
                theme: 'grid', 
                styles: {  
                    font: 'Roboto', 
                    fontSize: 10, 
                    cellPadding: 2, // уменьшенный паддинг 
                    halign: 'left', 
                    valign: 'middle', 
                    overflow: 'linebreak', 
                    cellWidth: 'wrap' 
                }, 
                headStyles: { 
                    fillColor: [200, 200, 200], 
                    textColor: 20, 
                    fontStyle: 'bold', 
                    halign: 'center' 
                }, 
                columnStyles: { 
                    0: { cellWidth: 'auto' }, 
                    1: { cellWidth: 30, halign: 'center' }, 
                    2: { cellWidth: 30, halign: 'center' } 
                } 
            }); 
            yPos = doc.lastAutoTable.finalY + 5; 
            doc.setFontSize(10); 
            doc.text(`Выход готового изделия: ${totalNetto} г`,  
            doc.internal.pageSize.width - 20, yPos, { align: 'right' }); 
            yPos += 10; 
        } 

        // Способ приготовления 
        if (recipe.steps && recipe.steps.length > 0) { 
            doc.setFontSize(12); 
            doc.text("4. Технология приготовления", 80, yPos); 
           
            yPos += 5; // уменьшенный отступ 
            recipe.steps.forEach((step, index) => { 
                if (yPos > doc.internal.pageSize.height - 20) { 
                    doc.addPage(); 
                    yPos = 20; 
                } 
                doc.setFontSize(10); 
                const stepText = `${index + 1}. ${step.text}`; 
                const splitStepText = doc.splitTextToSize(stepText, 170); 
                doc.text(splitStepText, 20, yPos); 
                yPos += splitStepText.length * 4 + 2; // уменьшенный межстрочный интервал 
            }); 
        } 

        // Подпись 
        doc.setFontSize(10); 
        doc.text("Разработал: Шеф-повар ____________", 20, doc.internal.pageSize.height - 20); 
        doc.text("Дата: " + new Date().toLocaleDateString(), doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 20, { align: 'right' }); 

        // Скачивание PDF
        doc.save(`ТТК - ${recipe.name}.pdf`); 
    }; 

    return ( 
        <button className="pdf-button" onClick={generatePDF}> 
            PDF
        </button> 
    ); 
} 

export default PdfGenerator;
